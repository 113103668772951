<template>
  <div class="main" @mousemove="handleMouseMove">
    <div class="title">
      <h3 class="logo">PZ_</h3>
      <router-link class="link" to="/">(projects)</router-link>
      <router-link class="link" to="/about">(info)</router-link>
    </div>
    <span class="text-ring"></span>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "App",
  components: {
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>

<style>
html,
body {
  height: 100%; /*both html and body*/
  background: white;
}
body {
  margin: 0; /*reset default margin*/
}
@font-face {
  font-family: "GTAmerica";
  src: url("./assets/GTAmericaRegular.ttf");
}
@font-face {
  font-family: "GTBold";
  src: url("./assets/GTAmericaBold.ttf");
}
@font-face {
  font-family: "AcuminPro";
  src: url("./assets/Acumin-RPro.otf");
}
#app,
.main,
body {
  cursor: url("./assets/icon16.png"), sw-resize;
  font-family: AcuminPro, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: black;
  background-size: 400% 200%;
  background-size: cover;
  animation: shine 5s linear infinite;
}

@keyframes shine {
  0% {
    background-position: 100% 50%;
  }
  100% {
    background-position: -33% 50%;
  }
}

.main {
  height: 100%;
}
.title {
  position: fixed;
  left: 0;
  font-weight: 700;
  padding-top: 10px;
  padding-left: 5px;
  display: flex;
  justify-content: flex-end;
  vertical-align: middle;
  padding-bottom: 4px;
  padding-left: 20px;
  text-align: right;
  z-index: 100;
}
.link {
  font-size: 24px;
  font-family: AcuminPro;
  margin: 5px 10px 5px 0px;
  padding: 2px 12px 6px 8px;
  border-radius: 10px;
  font-weight: 400;
  border: none;
  color: black;
  box-shadow: none;
  text-decoration: none;
  background: none;
}
.link:hover {
  color:rgba(39,94,231,1);
}
.router-link-active {
  color: black;
}
.logo {
  left: 0;
  margin: 12px 20px 15px 10px;
  font-size: 16px;
  letter-spacing: 0px;
  font-family: AcuminPro;
}
.initial-fs {
  z-index: 1000;
  position: absolute;
  background-color: red;
  height: 100%;
  width: 100%;
}
.clickme {
  color: black;
  z-index: 1001;
  font-size: 100px;
  text-align: center;
}
#mouse-icon {
  width: 20px;
  position: absolute;
}
.sd {
  font-family: AcuminPro;
  font-weight: 400;
  margin-top: -6px;
  font-size: 14px;
}
.contact-me{
  font-family: GTAmerica !important;
  font-weight: 600;
  margin-right: 10px;
  color: rgb(0, 46, 162);
  /* color: rgba(39,94,231,1); */
  border-radius: 20px;
  letter-spacing: -1.5px;
  font-size: 36px;
  padding-top: 100px;
}
.contact-box {
  display: flex;
  justify-content: flex-end;
  font-family: AcuminPro;
  font-weight: 400;
  bottom: 0;
  position: absolute;
  right: 0;
}
.contact-box p {
  margin: 10px;
  font-size: 18px;
  letter-spacing: -1px;
}
.contact {
  width: 100%;
  display: flex;
  position: absolute;
  bottom: 0;
  justify-content: space-between;
}
.doodle {
  position: absolute;
  width: 120px;
  transform: rotate(200deg);
  right: 0;
  top: 20px;
  right: 80px;
}
.projects-box {
  margin: 0px 30px 0 0;
  width: 45%;
  float: right;
  right: 0;
  position: absolute;
}
.first {
  height: 100%;
  width: 60%;
  position: fixed;
  background-size: contain;
  background-repeat: no-repeat;
  background: hsla(0, 0%, 76%, 1);
  background: radial-gradient(
    circle,
    rgb(70, 122, 255)  17%,
    rgb(58, 114, 255) 30%,
    white 59%
  );
  /* old one */
  /* background: -moz-radial-gradient(
    circle,
    rgba(39,94,231,1) 17%,
    rgba(39,94,231,1) 30%,
    white 59%
  ); */
  background: -webkit-radial-gradient(
    circle,
    rgb(70, 122, 255)  17%,
    rgb(58, 114, 255) 30%,
    white 59%
  );
  filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#C1C1C1", endColorstr="#D8D8D8", GradientType=1 );
  background-repeat: no-repeat;
  background-size: 700px 500px;
  background-position: -140px 60px;
  animation: gradient-animation 5s ease infinite;
}
.profile-box{
  width: 50%;
  height: 100%;
  position: fixed;
  right: 0;
  background: radial-gradient(
    circle,
    rgba(124, 161, 255, 0.8) 15%,
    rgb(143, 175, 255)  17%,
    rgba(124, 161, 255, 0.8) 20%,
    #bacdff 30%,
    white 59%
  );
  background-repeat: no-repeat;
  background-size: 700px 550px;
  background-position: 0 -200px;
  height: 100%;
  background-repeat: no-repeat;
  animation: gradient-animation 5s ease infinite;
}
.profile-text {
  margin: 120px 100px 0 20px;
  right: 0;
  /* position: fixed; */
}
.freelance-box{
  height: 100%;
  font-family: AcuminPro;
  width: 50%;
  text-align: center;
  animation: gradient-animation 5s ease infinite;
}
.freelance-content{
  margin: 100px 60px;
}
.freelance-category{
  /* color: rgb(0, 46, 162); */
}
.freelance-category p{
  margin-top: -10px;
}
.name {
  color: rgb(0, 46, 162);
  padding: 60px 90px;
  margin: 0 auto;
  margin-right: 20px;
  position: absolute;
}

.name-text-small {
  font-family: AcuminPro;
  animation: none;
  font-weight: 200;
  width: 400px;
  padding: 10px;
  margin-right: 20px;
  font-size: 24px;
  /* font-style: italic; */
  text-align: justify;
}
@keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
.name-text {
  font-family: GTAmerica;
  animation: none;
  font-weight: 600;
  margin-right: 10px;
  width: 400px;
  font-size: 80px;
  letter-spacing: -3px;
  margin-bottom: 0;
}
.project-container{
  border-bottom: 1px solid black;
  margin-left: 20px;
  padding: 20px 0px;
  margin-bottom: 10px;
  margin-top: 4px;
}
.project-text {
  display: flex;
}
.project-row {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}
.contact-text {
  font-family: Arial;
  /* letter-spacing: -1px; */
  font-size: 16px;
  font-weight: 200;
  text-align: right;
  margin: 10px;
  padding: 0 30px;
  cursor: pointer;
  color:rgba(39,94,231,1);
}
.contact-text:hover{
  color: rgb(0, 46, 162);
}
.about-text {
  justify-content: center;
  font-family: AcuminPro;
  animation: none;
  font-weight: 200;
  padding: 5px 10px 10px 10px;
  margin-right: 20px;
  font-size: 16px;
  text-align: justify;
  margin-top: 0;
}
.flex {
  width: 100%;
  display: inline-flex;
}
#profile-img {
  width: 140px;
  margin: 7px 10px 7px 10px;
  float: left
}
/* mobile */
@media only screen and (max-width: 600px) {
  .name-text-small {
    font-family: AcuminPro;
    animation: none;
    font-weight: 200;
    max-width: 100%;
    padding: 10px;
    margin-right: 20px;
    font-size: 18px;
    font-style: italic;
    text-align: justify;
  }
  .projects-box {
    margin: 0px 30px 0 0;
    right: 0;
    width: 100%;
    position: absolute;
    top: 100%;
  }
  .project-row {
    width: 100%;
    padding: 10px 20px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
  }
  .project-text {
    width: 80%;
  }
  .first {
    height: 100%;
    width: 100%;
    bottom: 20px;
    background-position: 0px 0px;
    position: absolute;
  }
  .profile-text {
    /* position: fixed; */
    padding: 30px;

    display: block;
    margin: 0 auto;
    /* width: 0%; */
  }
  .name {
    width: 70%;
    padding: 20px;
    margin: 0 auto;
    bottom: 20%;
    position: absolute;
  }
  .name-text {
    animation: none;
    margin-right: 10px;
    max-width: 100%;
    font-size: 60px;
    letter-spacing: -3px;
  }
  .title {
    position: absolute;
    z-index: 10001;
  }
  .logo {
   /* position: static; */
  }
  .contact {
    position: relative;
    display: block;
    padding-bottom: 20px;
    margin-right: 15px;
    margin-bottom: 20px;
  }
  .contact-text {
    text-align: left;
    font-size: 14px;
    margin: 5px 0px;
  }
  .contact-text:hover{
    color:rgba(39,94,231,1);
  }
  .about-text {
    font-size: 14px;
    font-weight: 200;
    padding: 0;
    margin-right: 0px;
  }
  .link {
    font-size: 18px;
    margin: 9px 10px 5px 0px;
  }
  .flex{
    flex-direction: column;
  }
  .freelance-box{
    width: 100%;
    height: 60%;
  }
  .profile-box{
    background-size: 500px 550px;
    width: 100%;
    position: relative;
  }
  .contact-me{
    padding-top: 40px
  }
  .freelance-content{
    margin: 60px 30px 30px 30px;
  }
  #profile-img {
    width: 160px;
    margin: 0px 10px 10px 0px;
    float: left
  }
  .freelance-category p{
    font-size: 14px;
    margin-top: -10px;
  }
}
/* tablets */
@media only screen and (min-width: 600px) and (max-width: 1023px) {
  .first{
    width: 100%
  }
  .name{
    padding: 60px 20px;
    width: 60%;
    margin-top: 200px;
  }
  .name-text{
    font-size: 50px;
  }
  .name-text-small{
    font-size: 16px;
    padding: 0;
    width: inherit;
  }
  .contact-text {
    font-size: 16px;
  }
  .profile-box{
    background-size: 400px 550px;
  }
}
</style>
