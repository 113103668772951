import { createApp } from "vue";
import App from "./App.vue";
import { createRouter, createWebHashHistory } from "vue-router";

const Main = () => import("./components/Main.vue");
const AboutPage = () => import("./components/AboutPage.vue");

const routes = [
  { path: "/", component: Main },
  { path: "/about", component: AboutPage },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});
createApp(App).use(router).mount("#app");
